.ant-layout {
  /* background: #ffffff !important; /* should be tailored to the theme */
}

.ant-layout-header {
  /* background: #FFFFFF !important; /* should be tailored to the theme */
}

.logo {
  width: 160px;
  height: 28px;
  background-image: url('../assets/Logo.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}

.hide-tablet {
  @media screen and (max-width: 768px) {
    display: none;
  }
}

.logo-container {
  width: 160px;
  display: inline-flex;
  margin-right: 30px;
}

.mainFooter {
  text-align: center;
}

.textCenter {
  text-align: center;
}

/* Connect component */
.pageTitle {
  font-size: 28px;
  font-weight: 600;
}

.editReturnDetailsRow {
  padding-bottom: 10px;
}

.editReturnDetailsRow .bulbIcon {
  color: #ffea00;
  font-size: 20px;
  position: absolute;
  right: 100%;
}

.ant-popover {
  max-width: 25%;
}
.ql-snow .ql-picker {
  height: 45px !important;
}

.removeFormMargin .ant-form-item {
  margin-bottom: 0;
}

.resolutionOptions .ant-select-enabled {
  width: 100%;
}

.exchangeItem {
  justify-content: center;
}

.exchangeItem .ant-list-item-meta-content {
  flex: none;
}

.exchangeItem .ant-list-item-meta-title {
  text-align: center;
}

.filter {
  cursor: pointer;
}

.filterButtons {
  margin: 20px 0 0 10px;
}

.filterButtons .anticon {
  margin-right: 5px;
}

.dashboardRangePicker {
  width: 300px;
  margin-left: 20px !important;
}

.cross-icon-reports {
  z-index: 1000;
  position: relative;
  right: 22px;
}

